/* eslint-disable react/jsx-filename-extension */
import {
  ChatBotOutlinedV2 as ChatBotOutlined,
  Google,
  Messenger,
  Instagram,
  InstagramSolid,
  Outlook,
} from '@groovehq/internal-design-system/lib/assets/icons'
import thumbsUpImg from '@groovehq/internal-design-system/lib/assets/images/thumbs-up.png'
import gmailImg from '@groovehq/internal-design-system/lib/assets/images/auth-gmail.png'
import {
  DRAWER_TYPE_CHANNELS_SELECT_EMAIL,
  DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS,
  DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS_OAUTH_MODAL,
  DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS_OAUTH,
  DRAWER_TYPE_CHANNELS_AUTHENTICATE,
  DRAWER_TYPE_CHANNELS_EMAIL_FORWARD,
  DRAWER_TYPE_CHANNELS_ADD_LIVE_CHAT,
  DRAWER_TYPE_CHANNELS_AUTHENTICATE_IMAP,
  DRAWER_TYPE_CHANNELS_FORWARDING_DETAILS,
} from 'ducks/drawers/types'
import {
  DRAWER_ID_FOLDERS_SELECT_EMAIL,
  DRAWER_ID_FOLDERS_CHANNEL_DETAILS,
  DRAWER_ID_FOLDERS_CHANNEL_DETAILS_OAUTH,
  DRAWER_ID_FOLDERS_CHANNELS_AUTHENTICATE,
  DRAWER_ID_FOLDERS_CHANNELS_EMAIL_FORWARD,
  DRAWER_ID_FOLDERS_CHANNELS_ADD_LIVE_CHAT,
} from 'ducks/drawers/ids'
import { doSelectChatChannel } from 'ducks/channels/actions'
import {
  emailClosed as EmailClosed,
  emailClosedV2 as EmailClosedV2,
  emailV2 as EmailV2,
  chatsV2 as LiveChat,
  officeLogo as OfficeLogo,
} from 'assets/icons/groove/v2'
import { doOpenNewWidgetPage } from 'subapps/settings/actions/redirect'

export const CHANNEL_MAIN_TYPES = {
  email: {
    drawer: {
      id: DRAWER_ID_FOLDERS_SELECT_EMAIL,
      type: DRAWER_TYPE_CHANNELS_SELECT_EMAIL,
      closeIgnoresStack: true,
    },
    name: 'Email',
    icon: <EmailV2 />,
    title: `Email`,
    description:
      'Connect your Gmail account or forward from any other email address.',
  },
  chat: {
    drawer: {
      id: DRAWER_ID_FOLDERS_CHANNELS_ADD_LIVE_CHAT,
      type: DRAWER_TYPE_CHANNELS_ADD_LIVE_CHAT,
      closeIgnoresStack: true,
    },
    doBeforeDrawer: doSelectChatChannel,
    name: 'Live Chat',
    icon: <LiveChat />,
    title: 'Live Chat',
    description:
      'Interact in real-time with customers on any page of your website or app.',
  },
  facebook: {
    drawer: {
      id: DRAWER_ID_FOLDERS_CHANNEL_DETAILS_OAUTH,
      type: DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS_OAUTH,
      closeIgnoresStack: true,
    },
    editDrawer: {
      id: DRAWER_ID_FOLDERS_CHANNELS_AUTHENTICATE,
      type: DRAWER_TYPE_CHANNELS_AUTHENTICATE,
      closeIgnoresStack: true,
    },
    name: 'Messenger',
    icon: <Messenger />,
    title: 'Messenger',
    description:
      'Connect your Facebook Page to handle incoming Messenger chats.',
    brandColor: '#0084ff',
  },
  instagram: {
    drawer: {
      id: DRAWER_ID_FOLDERS_CHANNEL_DETAILS_OAUTH,
      type: DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS_OAUTH,
    },
    editDrawer: {
      id: DRAWER_ID_FOLDERS_CHANNELS_AUTHENTICATE,
      type: DRAWER_TYPE_CHANNELS_AUTHENTICATE,
      closeIgnoresStack: true,
    },
    name: 'Instagram',
    icon: <Instagram />,
    solidIcon: <InstagramSolid />,
    title: 'Instagram',
    description:
      'Connect your Instagram account to handle incoming direct messages.',
    brandColor: '#0495f6',
  },
}

export const CHANNEL_EMAIL_TYPES = {
  google: {
    drawer: {
      id: DRAWER_ID_FOLDERS_CHANNEL_DETAILS_OAUTH,
      type: DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS_OAUTH_MODAL,
      closeIgnoresStack: true,
    },
    editDrawer: {
      id: DRAWER_ID_FOLDERS_CHANNELS_AUTHENTICATE,
      type: DRAWER_TYPE_CHANNELS_AUTHENTICATE,
      closeIgnoresStack: true,
    },
    name: 'Gmail',
    icon: <Google />,
    headerImg: gmailImg,
    title: 'Connect Gmail',
    editTitle: 'Link your Gmail account',
    description:
      'Start recieving your GSuite or Gmail email directly in Groove',
    importHistory: true,
    company: 'Google',
    totalStepCount: 4,
  },
  outlook: {
    drawer: {
      id: DRAWER_ID_FOLDERS_CHANNEL_DETAILS_OAUTH,
      type: DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS_OAUTH_MODAL,
      closeIgnoresStack: true,
    },
    editDrawer: {
      id: DRAWER_ID_FOLDERS_CHANNELS_AUTHENTICATE,
      type: DRAWER_TYPE_CHANNELS_AUTHENTICATE,
      closeIgnoresStack: true,
    },
    name: 'Outlook',
    icon: <Outlook className="grui p-2" />,
    headerImg: thumbsUpImg,
    title: 'Connect Outlook',
    editTitle: 'Link up your Outlook account',
    description: 'Start recieving your Outlook email directly in Groove',
    importHistory: true,
    company: 'Microsoft',
    totalStepCount: 4,
  },
  office365: {
    drawer: {
      id: DRAWER_ID_FOLDERS_CHANNEL_DETAILS_OAUTH,
      type: DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS_OAUTH_MODAL,
      closeIgnoresStack: true,
    },
    editDrawer: {
      id: DRAWER_ID_FOLDERS_CHANNELS_AUTHENTICATE,
      type: DRAWER_TYPE_CHANNELS_AUTHENTICATE,
      closeIgnoresStack: true,
    },
    name: 'Office 365',
    icon: <OfficeLogo className="grui p-3" />,
    iconBig: <OfficeLogo className="grui p-5" />,
    headerImg: thumbsUpImg,
    title: 'Connect Office 365',
    editTitle: 'Link up your Office 365 account',
    description: 'Start recieving your Office 365 email directly in Groove',
    importHistory: true,
    company: 'Microsoft',
    totalStepCount: 4,
  },
  imap: {
    drawer: {
      id: DRAWER_ID_FOLDERS_CHANNEL_DETAILS,
      type: DRAWER_TYPE_CHANNELS_CHANNEL_DETAILS,
      closeIgnoresStack: true,
    },
    editDrawer: {
      id: DRAWER_ID_FOLDERS_CHANNELS_AUTHENTICATE,
      type: DRAWER_TYPE_CHANNELS_AUTHENTICATE_IMAP,
      closeIgnoresStack: true,
    },
    name: 'IMAP',
    icon: <EmailClosed />,
    isSecondaryType: true,
    headerImg: thumbsUpImg,
    title: 'Connect IMAP',
    editTitle: 'Link up your IMAP account',
    description: 'Start recieving your IMAP email directly in Groove',
    importHistory: true,
    company: 'The email provider',
    totalStepCount: 4,
  },
  forwarding: {
    drawer: {
      id: DRAWER_ID_FOLDERS_CHANNEL_DETAILS,
      type: DRAWER_TYPE_CHANNELS_FORWARDING_DETAILS,
      closeIgnoresStack: true,
    },
    editDrawer: {
      id: DRAWER_ID_FOLDERS_CHANNELS_EMAIL_FORWARD,
      type: DRAWER_TYPE_CHANNELS_EMAIL_FORWARD,
      closeIgnoresStack: true,
    },
    name: 'Other email',
    icon: <EmailClosedV2 />,
    isSecondaryType: true,
    headerImg: thumbsUpImg,
    title: 'Other email client',
    editTitle: 'Forward your emails to Groove',
    totalStepCount: 4,
    description: 'Setup email forwarding to receive your email Groove',
  },
}

export const SECONDARY_EMAIL_CHANNEL = {
  name: 'Other email',
  icon: <EmailClosed />,
  title: 'Other email client',
  description: 'Setup email forwarding or IMAP to receive your email in Groove',
}

export const CHANNEL_ADDITIONAL_TYPES = {
  contact_widget: {
    doBeforeDrawer: doOpenNewWidgetPage,
    name: 'Contact widget',
    icon: <ChatBotOutlined />,
    title: 'Contact widget',
    description: 'Set up a contact widget for your website or app.',
  },
}

export const CHANNEL_TYPES = {
  ...CHANNEL_MAIN_TYPES,
  ...CHANNEL_EMAIL_TYPES,
}

export const drawerConfig = {
  DEFAULT_DRAWER_TITLE: `New ${app.t('mailbox')}`,
}
