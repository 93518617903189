import React, { useCallback } from 'react'
import cn from 'classnames'
import {
  button,
  heading,
  text,
} from '@groovehq/internal-design-system/lib/styles/elements'
import { styles } from '../styles'

const ChannelOption = ({
  title,
  children,
  className,
  type,
  onSelect,
  icon,
}) => {
  const handleClick = useCallback(
    () => {
      onSelect(type)
    },
    [onSelect, type]
  )
  return (
    <button
      type="button"
      css={[button.styles.preflight, text.styles.textSm, styles.optionCard]}
      className={cn('grui pt-7 pb-9 px-12', className)}
      onClick={handleClick}
      data-test-id={`button-list-item-${type}`}
    >
      {icon}
      <h5 css={[heading.styles.h5, text.styles.fontSemibold]}>{title}</h5>
      {children}
    </button>
  )
}

export default ChannelOption
