import React, { useCallback } from 'react'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import { propFunc } from 'util/functions'
import { useSelector } from 'react-redux'
import { selectIsInInbox } from 'selectors/location'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CHANNELS_FORWRADING_TEST } from 'ducks/drawers/types'
import { useRedirectToChannel } from 'ducks/channels/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'

function isConfirmed(state) {
  return ['active', 'confirmed'].includes(state)
}

const ForwardingActions = ({
  className,
  onSetupLater,
  onExit,
  channelId,
  channelType,
  channelState,
}) => {
  const isInInbox = useSelector(selectIsInInbox)
  const { rebuildExitAndRedirect } = useRedirectToChannel({
    channelId,
    onExit,
  })

  const {
    drawerId: forwardingTestDrawerId,
    openDrawer: openForwadingTestModal,
  } = useDrawer({
    id: null,
    type: DRAWER_TYPE_CHANNELS_FORWRADING_TEST,
    closeIgnoresStack: false,
  })

  const onSaveBtnClick = useCallback(
    hasConfirmationCode => {
      if (isConfirmed(channelState)) {
        if (isInInbox) rebuildExitAndRedirect()
        return
      }
      const confirmationCodeParam = hasConfirmationCode
        ? buildDrawerQueryParam(
            forwardingTestDrawerId,
            'drawerChannelHasConfirmationCode',
            1
          )
        : {}
      openForwadingTestModal(channelId, {
        query: {
          ...buildDrawerQueryParam(
            forwardingTestDrawerId,
            'drawerChannelType',
            channelType
          ),
          ...confirmationCodeParam,
        },
      })
    },
    [
      isInInbox,
      rebuildExitAndRedirect,
      channelState,
      channelId,
      channelType,
      openForwadingTestModal,
      forwardingTestDrawerId,
    ]
  )

  return (
    <div className={className}>
      <Modal.Button
        type="info"
        data-test-id="forwarding-verification-continue-button"
        onClick={propFunc(onSaveBtnClick, false)}
      >
        I&apos;ve setup my email forwarding
      </Modal.Button>
      <Modal.Button
        type="tertiary"
        className="grui mt-5"
        onClick={propFunc(onSaveBtnClick, true)}
      >
        My email provider has sent a confirmation code
      </Modal.Button>
      <Modal.Button type="link" className="grui mt-11" onClick={onSetupLater}>
        I&apos;ll finish my setup later
      </Modal.Button>
    </div>
  )
}

export default ForwardingActions
