import { useCallback, useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  queryStringToQueryId,
  queryIdToQuery,
} from 'ducks/searches/utils/query'
import { selectSearchEntitiesDenormalizedByQueryId } from 'ducks/searches/selectors'
import { doFetchTagsV2 } from 'ducks/tags/actions'
import { debounce } from 'util/functions'
import { stringToColor } from 'util/colors'

const defaultQueryId = 'entityType:tag pageSize:20 cursor:1'

export default function useSearchTags({
  searchDelayMs = 200,
  field = 'ids',
  prependNames = [],
}) {
  const dispatch = useDispatch()
  const [isReady, setIsReady] = useState(false)
  const [queryId, setQueryId] = useState(defaultQueryId)
  const { search = '' } =
    queryIdToQuery(queryId, { decodeUriKeys: ['search'] }) || {}
  const rawFilteredTags = useSelector(state =>
    selectSearchEntitiesDenormalizedByQueryId(state, queryId)
  )

  const doFetchTagsV2After = useMemo(
    () =>
      debounce(options => {
        dispatch(doFetchTagsV2(options))
      }, searchDelayMs),
    [dispatch, searchDelayMs]
  )

  useEffect(
    () => {
      async function fetchData() {
        await doFetchTagsV2After({ queryId, skipLoaded: true })
        if (!isReady) setIsReady(true)
      }
      fetchData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, queryId, doFetchTagsV2After]
  )

  const handleChangeSearch = useCallback(({ target }) => {
    const defaultQuery = queryIdToQuery(defaultQueryId)
    setQueryId(
      queryStringToQueryId(
        {
          ...defaultQuery,
          search: target.value,
        },
        { encodeUriKeys: ['search'] }
      )
    )
  }, [])

  const filteredTags = useMemo(
    () => {
      const prependTags = prependNames.map(name => ({
        id: name,
        name,
        color: stringToColor(name),
      }))

      let tags = rawFilteredTags

      if (field === 'names') {
        tags = rawFilteredTags.map(t => ({ ...t, id: t.name }))
      }

      if (prependTags.length) return [...prependTags, ...tags]

      return tags
    },
    [rawFilteredTags, field, prependNames]
  )

  return { search, handleChangeSearch, filteredTags, isReady }
}
