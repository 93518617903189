import React, { useEffect, useState, useMemo, useCallback } from 'react'
import KeyboardNavigator from 'components/KeyboardNavigator'
import Scroller from 'components/Scroller'
import { ENTER } from 'constants/keys'
import { throttle } from 'util/functions'
import Item from './Item'
import Header from './Header'
import Search from './Search'
import useShowHeaderShadow from './useShowSearchBorder'
import { styles } from './styles'
import useSearchItems from './useSearchItems'

const KEYBOARD_NAVIGATOR_KEYS = { select: [ENTER] }

const MenuWithSearch = ({
  focusElementRef,
  children,
  search,
  className,
  onScroll,
}) => {
  const [focusElement, setFocusElement] = useState()

  const throttledHandleScroll = useMemo(
    () => {
      return throttle(scrollTop => {
        onScroll(scrollTop)
      }, 100)
    },
    [onScroll]
  )

  const handleScroll = useCallback(
    e => {
      const scrollTop = e.scrollTop >= 0 ? e.scrollTop : e.target.scrollTop
      throttledHandleScroll(scrollTop)
    },
    [throttledHandleScroll]
  )

  useEffect(
    () => {
      if (focusElementRef?.current) {
        setFocusElement(focusElementRef.current)
      }
    },
    [focusElementRef]
  )

  return (
    <KeyboardNavigator
      activeKey={search}
      keys={KEYBOARD_NAVIGATOR_KEYS}
      focusElement={focusElement}
    >
      <Scroller
        css={styles.container}
        onScroll={onScroll ? handleScroll : undefined}
      >
        <div role="menu" className={className}>
          {children}
        </div>
      </Scroller>
    </KeyboardNavigator>
  )
}

MenuWithSearch.Item = Item
MenuWithSearch.Header = Header
MenuWithSearch.Search = Search
MenuWithSearch.styles = styles
MenuWithSearch.useShowHeaderShadow = useShowHeaderShadow
MenuWithSearch.useSearchItems = useSearchItems

export default MenuWithSearch
