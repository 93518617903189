import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormState } from 'react-hook-form'
import { redirect } from 'redux-first-router'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import {
  doClearChannelDraft,
  doTryCreateChannelAndLoadResource,
  doUpdateChannel,
} from 'ducks/channels/actions'
import {
  useConfirmHoldsCallback,
  releaseHold,
  retainHold,
} from 'util/dirtyHolds'
import {
  useRedirectToChannel,
  useChannelDetailsConfig,
  useChannel,
} from 'ducks/channels/hooks'
import { SETTINGS_CHANNELS_PAGE } from 'subapps/settings/types'
import { selectChannelCreationStepCount } from 'ducks/channels/selectors'
import ChannelDetailsForm from '../../../../shared/forms/ChannelDetails'
import { useAdminAccess } from '../../../NoAccess/hooks'
import { styles } from '../styles'
import { CHANNEL_TYPES } from '../../Channels.data'

const Actions = ({
  formId,
  control,
  onClose,
  isSaving,
  saveBtnText,
  firstStep,
}) => {
  const { isValid } = useFormState({ control })
  const handleBack = useCallback(
    () => {
      onClose({ ignoreStack: false })
    },
    [onClose]
  )

  return (
    <>
      <Modal.Button
        type="info"
        htmlType="submit"
        data-test-id="forwarding-details-form-continue-button"
        disabled={!isValid || isSaving}
        form={formId}
      >
        <>
          {isSaving && (
            <span>
              Saving<AnimatedEllipsis />
            </span>
          )}
          {!isSaving && saveBtnText}
        </>
      </Modal.Button>
      {!firstStep && (
        <Modal.Button type="link" className="grui mt-11" onClick={handleBack}>
          Back
        </Modal.Button>
      )}
    </>
  )
}

const ChannelForwardingDetails = ({
  onClose,
  onExit,
  drawerChannelType: channelType = 'forwarding',
  drawerResourceId: channelId,
  drawerId,
  firstStep,
}) => {
  const dispatch = useDispatch()
  const [isSaving, setIsSaving] = useState(false)
  const [hasError, setHasError] = useState(false)
  const creationStepCount = useSelector(state =>
    selectChannelCreationStepCount(state, CHANNEL_TYPES[channelType])
  )
  const { drawer: nextDrawer, canUseFeature } = useChannelDetailsConfig(
    channelType
  )
  const { channel } = useChannel(channelId, { useCachedIfAvailable: true })
  const { drawerId: nextDrawerId, openDrawer: openNextDrawer } = useDrawer(
    nextDrawer
  )
  useAdminAccess(onClose)

  const { rebuildExitAndRedirect } = useRedirectToChannel({
    channelId,
    onExit,
  })

  const formId = `${drawerId}-form`

  const handleOnClose = useConfirmHoldsCallback(formId, onClose, [onClose])

  const handleOnExit = useConfirmHoldsCallback(
    null,
    () => {
      dispatch(doClearChannelDraft(channelId))
      onExit()
    },
    [dispatch, onExit, channelId]
  )

  useEffect(
    () => {
      if (canUseFeature) return
      if (channelId !== 'new') return

      handleOnExit()
      dispatch(
        redirect({
          type: SETTINGS_CHANNELS_PAGE,
        })
      )
    },
    [handleOnExit, dispatch, canUseFeature, channelType, channelId]
  )

  const saveBtnText = useMemo(
    () => {
      if (!nextDrawer) {
        return 'Finish & take me to my channel'
      }

      return 'Next Step'
    },
    [nextDrawer]
  )

  const onSubmit = useCallback(
    async data => {
      try {
        setIsSaving(true)
        setHasError(false)
        let currentChannelId = channelId

        if (channel) {
          await dispatch(
            doUpdateChannel(channelId, data, {
              channelType,
              rebuildMenu: false,
            })
          )
          releaseHold(drawerId)
        } else {
          const { resource, createdChannelId } = await dispatch(
            doTryCreateChannelAndLoadResource(channelId, channelType, data, {
              rebuild: false,
              redirect: false,
            })
          )
          currentChannelId = createdChannelId
          releaseHold(drawerId)
          if (!resource) {
            onClose()
            return
          }
        }

        setIsSaving(false)

        if (!nextDrawer) {
          rebuildExitAndRedirect()
          return
        }

        openNextDrawer(currentChannelId, {
          query: {
            ...buildDrawerQueryParam(
              nextDrawerId,
              'drawerChannelType',
              channelType
            ),
          },
        })
      } catch (error) {
        setIsSaving(false)
        setHasError(true)
        retainHold()
      }
    },
    [
      dispatch,
      openNextDrawer,
      nextDrawerId,
      channelId,
      channelType,
      drawerId,
      onClose,
      rebuildExitAndRedirect,
      nextDrawer,
      channel,
    ]
  )

  const actionsComponentProps = useMemo(
    () => {
      return {
        formId,
        onClose: handleOnClose,
        isSaving,
        saveBtnText,
        firstStep,
      }
    },
    [formId, handleOnClose, isSaving, saveBtnText, firstStep]
  )

  const footerRef = useRef(null)

  return (
    <Modal
      dataTestId="channels-details-forwarding"
      portal="#drawer-root"
      open
      onClose={handleOnExit}
      backdropTransparency="light"
    >
      <ProgressDots
        count={creationStepCount}
        now={2}
        className="grui mt-4 mb-13"
      />
      <MessageCard type="negative" className="grui my-12" visible={!!hasError}>
        An error occurred adding this channel. Please try again.
      </MessageCard>
      <Modal.Title>Setup your forwarding info</Modal.Title>
      <Modal.Description>
        To start receiving your email in your Groove account, you need to setup
        forwarding.
      </Modal.Description>
      <div css={styles.content} className="grui pt-5 pb-6">
        <ChannelDetailsForm
          formId={formId}
          channelId={channelId}
          channelType={channelType}
          onSubmit={onSubmit}
          fullWidth
          actionsPortalRef={footerRef}
          actionsComponent={Actions}
          actionsComponentAdditionalProps={actionsComponentProps}
          showForwardEmail={false}
        />
        <div ref={footerRef} className="grui mt-18 text-center" />
      </div>
    </Modal>
  )
}

export default ChannelForwardingDetails
