import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styles as modalBtnsStyles } from '@groovehq/internal-design-system/lib/components/ModalBtns/ModalBtns.styles'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { selectBillingAccount } from 'ducks/billing/selectors/selectBillingAccount'
import { doFetchBillingData } from 'ducks/billing/operations'
import { isEmpty } from 'util/objects'
import debug from 'util/debug'
import { useBilling, usePricingChangeInfo } from 'ducks/billing/hooks'
import { useDrawer } from 'ducks/drawers/hooks'
import {
  DRAWER_TYPE_BILLING_EXTERNAL_APPROVE,
  DRAWER_TYPE_BILLING_DOWNGRADE_PLAN,
  DRAWER_TYPE_BILLING_DOWNGRADE_PLAN_CONFIRMATION,
} from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { selectIsBootstrapTypedInStates } from 'selectors/bootstrap/makeSelectIsBootstrapTypesLoaded'
import { capitalize } from 'util/strings'
import { uniq } from 'util/arrays'
import { strftime } from 'util/date'
import { doFetchSettingsBootstrapData } from 'actions/app'
import PlanRow from '../PlanRow'
import { AdminAccessDrawer } from '../../NoAccess'
import { styles } from '../styles'
import Features from '../Features'
import TotalNextRecurringInvoice from '../BillingChangePlanDetails/TotalNextRecurringInvoice'
import { BillingSuperAdminOptions } from '../BillingSuperAdminOptions'

const changeVerb = verb =>
  verb.endsWith('e') ? `${verb.slice(0, -1)}ing` : `${verb}ing`

const BillingChangePlanDetails = ({
  open,
  onExit,
  drawerResourceId: newPricingIdsPipeDelimited,
  drawerBillingCycle,
  drawerLimitsConfirmed = false,
}) => {
  const dispatch = useDispatch()

  const inputNewPricingIds = useMemo(
    () => uniq(newPricingIdsPipeDelimited.split('|')),
    [newPricingIdsPipeDelimited]
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [superAdminOptions, setSuperAdminOptions] = useState({})
  const { updateSubscription, isActiveTrial } = useBilling()

  const {
    totalTypeWithChanges,
    changeType,
    currentBillingCycle,
    newBillingCycle,
    isLimitsExceeded,
    isUpgradingToAnnual,
    isCycleChange,
    newPricingIds,
    changes,
    nextBillDate,
    currency,
    newPlansInfo,
    discount,
    removedFeatures,
    planLimitsExceeded,
  } = usePricingChangeInfo(inputNewPricingIds, drawerBillingCycle)

  const isScheduled =
    changeType === 'downgrade' &&
    superAdminOptions.scheduleChanges === undefined

  const billingAccount = useSelector(selectBillingAccount)

  const isLoading =
    !useSelector(state =>
      selectIsBootstrapTypedInStates(state, ['billing'], ['LOADED'])
    ) || isEmpty(billingAccount)

  const { openDrawer: openBillingExternalApprove } = useDrawer({
    type: DRAWER_TYPE_BILLING_EXTERNAL_APPROVE,
  })

  const {
    drawerId: downgradePlanDrawerId,
    openDrawer: openDowngradePlan,
  } = useDrawer({
    type: DRAWER_TYPE_BILLING_DOWNGRADE_PLAN,
  })

  const {
    drawerId: billingDowngradeConfirmationDrawerId,
    openDrawer: openBillingDowngradeConfirmation,
  } = useDrawer({
    type: DRAWER_TYPE_BILLING_DOWNGRADE_PLAN_CONFIRMATION,
  })

  const onSuperAdminChange = useCallback(
    evt => {
      const id = evt.target.id
      setSuperAdminOptions(prevState => {
        // Determine the new value based on the current value
        let newValue
        if (id === 'prorate') {
          if ([null, undefined].includes(prevState[id])) {
            newValue = true // If currently undefined, set to true
          } else if (prevState[id] === true) {
            newValue = false // If currently true, set to false
          } else if (prevState[id] === false) {
            newValue = undefined // If currently false, set to undefined
          }
        } else if (id === 'scheduleChanges') {
          if ([null, undefined].includes(prevState[id])) {
            newValue = false // If currently undefined, set to true
          } else if (prevState[id] === false) {
            newValue = undefined // If currently false, set to undefined
          }
        }
        return {
          ...prevState,
          [id]: newValue,
        }
      })
    },
    [setSuperAdminOptions]
  )

  const onSubmit = useCallback(
    async () => {
      setIsSubmitting(true)
      const data = {
        ...superAdminOptions,
        pricingIds: newPricingIds,
        cycle: newBillingCycle,
      }
      try {
        const response = await updateSubscription(data, {
          successMessage: `Your ${changeType} was successful`,
          failed: `Your ${changeType} has failed`,
          convertTrials: false,
          meta: {
            pricing_ids: newPricingIds,
            billing_cycle: newBillingCycle,
            change_type: changeType,
            was_trial: isActiveTrial,
          },
        })
        const {
          updateSubscription: {
            pendingSubscription: { approvalUrl } = {},
          } = {},
        } =
          response || {}
        if (approvalUrl) {
          openBillingExternalApprove()
        } else if (isScheduled) {
          dispatch(doFetchSettingsBootstrapData())
          openBillingDowngradeConfirmation(newPricingIds.join('|'), {
            query: {
              ...buildDrawerQueryParam(
                billingDowngradeConfirmationDrawerId,
                'drawerBillingCycle',
                newBillingCycle
              ),
            },
          })
        } else {
          dispatch(doFetchSettingsBootstrapData())
          onExit()
        }
      } catch (error) {
        debug(error)
        setIsSubmitting(false)
      }
    },
    [
      dispatch,
      newBillingCycle,
      newPricingIds,
      onExit,
      openBillingExternalApprove,
      updateSubscription,
      billingDowngradeConfirmationDrawerId,
      openBillingDowngradeConfirmation,
      changeType,
      superAdminOptions,
      isScheduled,
      isActiveTrial,
    ]
  )

  useEffect(
    () => {
      dispatch(doFetchBillingData())
    },
    [dispatch]
  )

  useEffect(
    () => {
      if (isScheduled && isLimitsExceeded && !drawerLimitsConfirmed) {
        openDowngradePlan(newPricingIdsPipeDelimited, {
          query: {
            ...buildDrawerQueryParam(
              downgradePlanDrawerId,
              'drawerBillingCycle',
              drawerBillingCycle
            ),
          },
        })
      }
    },
    [
      newPricingIdsPipeDelimited,
      openDowngradePlan,
      downgradePlanDrawerId,
      drawerLimitsConfirmed,
      drawerBillingCycle,
      isLimitsExceeded,
      changeType,
      isScheduled,
    ]
  )

  useEffect(
    () => {
      // If no new pricing ids are provided or the new pricing ids match the existing pricing ids
      // juse close the drawer
      if (newPricingIds.length === 0 || totalTypeWithChanges === 0) {
        onExit()
      }
    },
    [newPricingIds, onExit, totalTypeWithChanges]
  )

  const footer = (
    <div css={[modalBtnsStyles.base, styles.btns]}>
      {isScheduled && (
        <Button type="tertiary" size="big" onClick={onExit}>
          No thanks, I’d like to keep my current plan
        </Button>
      )}
      <Button
        type="info"
        size="big"
        onClick={onSubmit}
        disabled={isSubmitting || isLoading}
      >
        {isSubmitting
          ? `${changeVerb(capitalize(changeType))}...`
          : `${capitalize(changeType)} now`}
      </Button>
    </div>
  )

  const totalNewCyclePrice = useMemo(
    () => {
      return newPlansInfo.reduce((total, info) => {
        return total + info.lineItem.cyclePrice
      }, 0)
    },
    [newPlansInfo]
  )

  const showDowngradeFeatureRemoval =
    planLimitsExceeded && removedFeatures.length > 0

  return (
    <>
      <AdminAccessDrawer
        title={`${capitalize(changeType)} subscription`}
        open={open}
        footer={footer}
        onClose={onExit}
        isLoading={isLoading}
      >
        <div className="grui pt-10" css={[styles.container]}>
          <div css={text.styles.textNormal}>
            <>
              You are about to {isScheduled ? 'schedule the ' : ''} {changeType}{' '}
              {isScheduled && 'of '}your <strong>{currentBillingCycle}</strong>{' '}
              subscription{isCycleChange && (
                <>
                  {' to '}
                  <strong>{newBillingCycle}</strong> billing
                </>
              )}{' '}
              {showDowngradeFeatureRemoval && (
                <>. You{"'"}ll loose access to the following features </>
              )}
              {isScheduled && (
                <>
                  on{' '}
                  <strong>
                    {strftime('%A, %Do %B ‘%y', nextBillDate * 1000)}.
                  </strong>
                </>
              )}
              {!isScheduled && (
                <>
                  <strong>immediately.</strong>
                </>
              )}
            </>
          </div>
          {showDowngradeFeatureRemoval && (
            <Features
              newPricingIds={newPricingIds}
              newBillingCycle={drawerBillingCycle}
              showOnlyRemoved
              className="grui mt-4"
            />
          )}
          <div
            css={styles.planChangesCard}
            className="grui pt-10 mt-12 -ml-7 -mr-7"
          >
            <div className="grui pt-2 px-7">
              {changes
                .filter(change => {
                  const { newPricingId, newQuantity = 0 } = change
                  return newPricingId && newQuantity > 0
                })
                .map(change => {
                  const {
                    newInfo: info,
                    newInfo: {
                      displayName,
                      pricing: { type: pricingType, pricingModel, usageFrom },
                      lineItem: {
                        cyclePrice,
                        cycleDiscountPrice,
                        itemDiscountPrice,
                        monthlyItemDiscountPrice,
                        itemPrice = 0,
                        quantity,
                        percentOff,
                      },
                      billingCycle,
                    },
                  } = change

                  return (
                    <PlanRow
                      key={`new-${info.pricing.id}`}
                      name={displayName}
                      product={pricingType}
                      cyclePrice={cyclePrice}
                      itemPrice={itemPrice}
                      cycleDiscountPrice={cycleDiscountPrice}
                      itemDiscountPrice={itemDiscountPrice}
                      monthlyItemDiscountPrice={monthlyItemDiscountPrice}
                      quantity={quantity}
                      className="grui mb-8"
                      isNew
                      isUpgradingToAnnual={isUpgradingToAnnual}
                      pricingModel={pricingModel}
                      planCycle={billingCycle}
                      isCycleChange={isCycleChange}
                      usageFrom={usageFrom}
                      disableNewCurrentIndicator
                      percentOff={percentOff}
                    />
                  )
                })}
            </div>
            <TotalNextRecurringInvoice
              nextBillDate={nextBillDate}
              currency={currency}
              discount={discount}
              totalNewCyclePrice={totalNewCyclePrice}
              isUpgrade={changeType === 'upgrade'}
            />
          </div>
          <BillingSuperAdminOptions
            onChange={onSuperAdminChange}
            {...superAdminOptions}
          />
        </div>
      </AdminAccessDrawer>
    </>
  )
}

export default BillingChangePlanDetails
