import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import Link from 'redux-first-router-link'
import { useSelector } from 'react-redux'
import Tooltip from '@groovehq/internal-design-system/lib/components/Tooltip/Tooltip'
import Button from '@groovehq/internal-design-system/lib/components/Button/Button'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import KeyboardNavigator from 'components/KeyboardNavigator'
import { debounceWithArgument, propFunc } from 'util/functions'
import { SETTINGS_BILLING_PAGE } from 'subapps/settings/types'
import { useFeature } from 'ducks/billing/hooks'
import { camelize } from 'util/strings'
import { selectPendingDowngradePlans } from 'ducks/billing/selectors/selectPendingDowngradePlans'
import { capture } from 'ducks/tracking/actions'
import { styles } from './styles'

const captureDebounced = debounceWithArgument((eventName, options = {}) => {
  capture(eventName, options)
}, 100)

const TO_SETTINGS_BILLING_PAGE = {
  type: SETTINGS_BILLING_PAGE,
  payload: {
    tab: 'plans',
  },
}

const Item = ({
  itemKey,
  children,
  tooltip,
  onSelect,
  selectedKey,
  visible,
  className,
  product,
  feature,
  checkProductFeatureLimit = false,
  disabled,
  type = '',
}) => {
  const { isFeatureEnabled } = useFeature(feature)
  const featureAvailable =
    checkProductFeatureLimit && feature ? isFeatureEnabled : true
  const { [product]: pendingDowngradeInPlan } = useSelector(
    selectPendingDowngradePlans
  )
  const featureAvailableInPendingPlan =
    checkProductFeatureLimit && feature && pendingDowngradeInPlan
      ? pendingDowngradeInPlan?.features[camelize(feature)]
      : true

  const itemRef = useRef()
  useEffect(
    () => {
      // Scroll the selected element into view
      if (selectedKey === itemKey && visible && itemRef?.current) {
        itemRef.current.scrollIntoViewIfNeeded()
      }
    },
    [selectedKey, itemKey, visible]
  )
  useEffect(
    () => {
      if (feature && checkProductFeatureLimit && !featureAvailable) {
        captureDebounced(`features impression ${feature}`)
      }
    },
    [feature, checkProductFeatureLimit, isFeatureEnabled, featureAvailable]
  )

  const upgradeNotice = (
    <div css={[text.styles.textXs, text.styles.textWhite]}>
      This feature is not
      <br />
      available on your current plan
      <br />
      <Button
        as={Link}
        to={TO_SETTINGS_BILLING_PAGE}
        css={styles.upgradeBtn}
        type="info"
        size="small"
        className="grui px-12 py-3 mt-6"
      >
        See Plans
      </Button>
    </div>
  )

  const content = (
    <div
      className={cn('grui truncate', tooltip && 'flex items-center')}
      ref={itemRef}
    >
      {children}
      {tooltip &&
        featureAvailable && (
          <Tooltip
            title={tooltip}
            strategy="fixed"
            portal="#overlays"
            css={styles.tooltip}
          >
            <div css={styles.tooltipIcon}>i</div>
          </Tooltip>
        )}
    </div>
  )

  const contentWithFeatureTooltip = (
    <Tooltip
      title={
        featureAvailable
          ? `This ${type} is part of our Advanced Rules`
          : upgradeNotice
      }
      strategy="absolute"
      interactiveTooltip
      className={cn(
        'grui',
        !featureAvailableInPendingPlan ? 'py-8' : 'pt-6 pb-11 px-11'
      )}
      css={styles.limitedTooltip}
      maxWidth={featureAvailable ? 160 : undefined}
    >
      <div className="grui flex items-center" css={styles.noMinWidth}>
        {content}
        <span className="grui px-3" css={text.styles.textNormal}>
          ⚡
        </span>
      </div>
    </Tooltip>
  )

  if (!featureAvailable || disabled)
    return (
      <div
        role="menuitem"
        css={[styles.item, styles.disabledItem]}
        className={className}
      >
        {featureAvailable ? content : contentWithFeatureTooltip}
      </div>
    )

  return (
    <KeyboardNavigator.Item
      key={itemKey}
      onSelect={propFunc(onSelect, itemKey)}
      css={styles.item}
      active={itemKey === selectedKey}
      className={cn(itemKey === selectedKey && 'selected', className)}
      role="menuitem"
    >
      {featureAvailableInPendingPlan ? content : contentWithFeatureTooltip}
    </KeyboardNavigator.Item>
  )
}

export default Item
