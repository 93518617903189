import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'
import {
  doClearChannelDraft,
  doTryCreateChannelAndLoadResource,
} from 'ducks/channels/actions'
import {
  useConfirmHoldsCallback,
  releaseHold,
  retainHold,
} from 'util/dirtyHolds'
import {
  useExitMailboxCreationIfFeatureIsDisabled,
  useGoNextStepAfterMailboxDetails,
} from 'ducks/channels/hooks'
import { selectChannelCreationStepCount } from 'ducks/channels/selectors'
import { doVerifyIMAP } from 'ducks/mailboxes/actions'
import { isIMAPChannelType } from 'ducks/channels/channelTypes'
import {
  EMAIL_SERVER_AUTH_SSL,
  EMAIL_SERVER_LOGIN_EMAIL,
  EMAIL_SERVER_LOGIN_PASSWORD,
  IMAP_SERVER,
  SMTP_SERVER,
} from 'ducks/mailboxes/constants'
import ChannelDetailsForm from '../../../../shared/forms/ChannelDetails'
import { useAdminAccess } from '../../../NoAccess/hooks'
import ActionsWithMessage from '../ActionsWithMessage'
import { styles } from '../styles'
import { oAuthErrorToErrorCode } from '../oAuthError'
import { CHANNEL_TYPES } from '../../Channels.data'

const ChannelDetails = ({
  onClose,
  onExit,
  drawerChannelType: channelType = 'imap',
  drawerResourceId: channelId,
  drawerId,
  firstStep,
}) => {
  const dispatch = useDispatch()
  const [errorCode, setErrorCode] = useState(null)
  const [isOAuthSccessful, setIsOAuthSccessful] = useState()
  const [newChannelId, setNewChannelId] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const creationStepCount = useSelector(state =>
    selectChannelCreationStepCount(state, CHANNEL_TYPES[channelType])
  )

  const { name } = CHANNEL_TYPES[channelType] || {}
  const timeoutRef = useRef()

  useAdminAccess(onClose)

  useExitMailboxCreationIfFeatureIsDisabled({
    newChannelId,
    channelType,
    onExit,
  })

  const { completed } = useGoNextStepAfterMailboxDetails({
    newChannelId,
    channelType,
    onExit,
  })

  const formId = `${drawerId}-form`
  const isIMAPChannel = isIMAPChannelType(channelType)

  const handleOnClose = useConfirmHoldsCallback(formId, onClose, [onClose])

  const handleOnExit = useConfirmHoldsCallback(
    null,
    () => {
      dispatch(doClearChannelDraft(channelId))
      onExit()
    },
    [dispatch, onExit, channelId]
  )

  const handleError = useCallback(
    error => {
      setErrorCode(oAuthErrorToErrorCode(error, channelType))
      // eslint-disable-next-line no-console
      console.error(error)
    },
    [channelType]
  )

  const onSubmit = useCallback(
    async data => {
      try {
        setErrorCode(null)
        setIsSaving(true)

        if (isIMAPChannel) {
          const inComingEmailServer = data[IMAP_SERVER]
          // eslint-disable-next-line no-param-reassign
          data[SMTP_SERVER] = {
            ...data[SMTP_SERVER],
            [EMAIL_SERVER_AUTH_SSL]: inComingEmailServer[EMAIL_SERVER_AUTH_SSL],
            [EMAIL_SERVER_LOGIN_EMAIL]:
              inComingEmailServer[EMAIL_SERVER_LOGIN_EMAIL],
            [EMAIL_SERVER_LOGIN_PASSWORD]:
              inComingEmailServer[EMAIL_SERVER_LOGIN_PASSWORD],
          }
          await dispatch(doVerifyIMAP(data))
        }

        const { resource, createdChannelId } = await dispatch(
          doTryCreateChannelAndLoadResource(channelId, channelType, data, {
            rebuild: false,
            redirect: false,
          })
        )
        setNewChannelId(createdChannelId)
        setIsOAuthSccessful(true)
        timeoutRef.current = setTimeout(() => {
          setNewChannelId(createdChannelId)
          timeoutRef.current = null
        }, 1000)
        releaseHold(formId)
        if (!resource) {
          onClose()
          return
        }

        setIsSaving(false)
      } catch (error) {
        setIsSaving(false)
        retainHold(formId)
        handleError(error)
      }
    },
    [
      dispatch,
      channelId,
      channelType,
      formId,
      onClose,
      handleError,
      isIMAPChannel,
    ]
  )

  const actionsComponentProps = useMemo(
    () => {
      return {
        formId,
        onClose: handleOnClose,
        isInstalling: isSaving,
        completed,
        firstStep,
        channelType,
        errorCode,
        isOAuthSccessful,
        channelId: newChannelId,
        onExit,
      }
    },
    [
      formId,
      handleOnClose,
      isSaving,
      completed,
      firstStep,
      channelType,
      errorCode,
      isOAuthSccessful,
      newChannelId,
      onExit,
    ]
  )

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const footerRef = useRef(null)

  return (
    <Modal
      dataTestId={`channels-details-${channelType}`}
      portal="#drawer-root"
      open
      onClose={handleOnExit}
      backdropTransparency="light"
      className="grui mt-18"
    >
      <ProgressDots
        count={creationStepCount}
        now={2}
        className="grui mt-4 mb-13"
      />
      <Modal.Title>Setup your {name} info</Modal.Title>
      <Modal.Description>
        {`To start receiving your email in your Groove account, you need to provide your email account information`}
      </Modal.Description>
      <div css={styles.content} className="grui pt-5 pb-6">
        <ChannelDetailsForm
          formId={formId}
          channelId={channelId}
          channelType={channelType}
          onSubmit={onSubmit}
          fullWidth
          actionsPortalRef={footerRef}
          actionsComponent={ActionsWithMessage}
          actionsComponentAdditionalProps={actionsComponentProps}
          showForwardEmail={false}
          inDrawer
        />
        <div ref={footerRef} className="grui mt-18 text-center" />
      </div>
    </Modal>
  )
}

export default ChannelDetails
