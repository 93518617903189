import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import Dropdown, {
  divider,
} from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { emptyArr } from 'util/arrays'
import MenuWithSearch, {
  useShowHeaderShadow,
  styles as menuWithSearchStyles,
} from '../MenuWithSearch'
import { Footer, styles, useMultiSelect } from '../MultiSelectMenu'

const ratingOptions = [
  { id: 'awesome', name: 'Awesome' },
  { id: 'ok', name: 'Ok' },
  { id: 'bad', name: 'Bad' },
]

const ratingOptionsById = {
  awesome: 'Awesome',
  ok: 'Ok',
  bad: 'Bad',
}

const RatingsDropdown = ({
  value: inputValue,
  setValue,
  selectedIds,
  onSelectedIdsChange,
}) => {
  const value = inputValue || emptyArr
  const [menuVisible, setMenuVisible] = useState(false)
  const { handleScroll, shouldShowHeaderShadow } = useShowHeaderShadow(
    menuVisible
  )
  const { allChecked, handleCheckAllChange, handleChange } = useMultiSelect(
    ratingOptions,
    onSelectedIdsChange,
    selectedIds
  )

  const handleMenuVisibleChange = useCallback(visible => {
    setMenuVisible(visible)
  }, [])

  const handleSelect = useCallback(
    () => {
      setValue(selectedIds)
      setMenuVisible(false)
    },
    [setValue, selectedIds]
  )

  const handleCancel = useCallback(() => {
    setMenuVisible(false)
  }, [])

  const menu = useMemo(
    () => {
      return (
        <MenuWithSearch onScroll={handleScroll}>
          <Checkbox
            css={styles.checkbox}
            truncate
            id={'any-rating'}
            onChange={handleCheckAllChange}
            checked={allChecked}
            gap={16}
            className="grui px-10 py-5"
          >
            Any rating
          </Checkbox>

          {divider}

          <div className="grui pt-3 pb-5">
            {ratingOptions.map(({ id, name }) => (
              <MenuWithSearch.Item itemKey={id} key={id}>
                <Checkbox
                  css={styles.checkbox}
                  truncate
                  id={id}
                  onChange={handleChange}
                  checked={selectedIds.some(itemId => itemId === id)}
                  gap={16}
                >
                  {name}
                </Checkbox>
              </MenuWithSearch.Item>
            ))}
          </div>
        </MenuWithSearch>
      )
    },
    [allChecked, handleChange, handleScroll, handleCheckAllChange, selectedIds]
  )

  const footer = useMemo(
    () => (
      <Footer
        onSelect={handleSelect}
        onCancel={handleCancel}
        saveBtnTitle="Select"
      />
    ),
    [handleSelect, handleCancel]
  )

  const buttonText = useMemo(
    () => {
      if (value.length === ratingOptions.length) {
        return 'Any rating'
      }

      if (value.length) {
        return value.map(v => ratingOptionsById[v]).join(', ')
      }

      return 'Select ratings'
    },
    [value]
  )

  useEffect(
    () => {
      if (!menuVisible) {
        onSelectedIdsChange(value)
      }
    },
    [menuVisible, onSelectedIdsChange, value]
  )

  return (
    <Dropdown
      overlay={menu}
      footer={footer}
      css={[
        text.styles.textSm,
        styles.dropdownContainer,
        shouldShowHeaderShadow && menuWithSearchStyles.showHeaderShadow,
      ]}
      visible={menuVisible}
      onVisibleChange={handleMenuVisibleChange}
      emptyHint=""
      autoHeight
    >
      <Dropdown.Button css={styles.dropdownBtn} size="small">
        {buttonText}
      </Dropdown.Button>
    </Dropdown>
  )
}

export default RatingsDropdown
