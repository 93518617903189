import { useCallback, useMemo } from 'react'

const useMultiSelect = (options = [], onChange, checkedList = []) => {
  // If check all items
  const allChecked = useMemo(() => checkedList.length === options.length, [
    checkedList,
    options,
  ])

  const indeterminate = useMemo(
    () => {
      // Should check indeterminate when options are changed
      return checkedList.length < options.length && checkedList.length > 0
    },
    [checkedList.length, options.length]
  )

  // Handle check one item
  const handleChange = useCallback(
    e => {
      let list = []
      const { checked, id } = e.target
      if (checked) {
        list = [...checkedList, id]
      } else {
        list = checkedList.filter(itemId => itemId !== id)
      }

      // Keep the order as in the options
      list.sort((a, b) => {
        return (
          options.findIndex(item => item.id === a) -
          options.findIndex(item => item.id === b)
        )
      })
      onChange(list)
    },
    [checkedList, onChange, options]
  )

  // Handle select all
  const handleCheckAllChange = useCallback(
    e => {
      onChange(e.target.checked ? options.map(o => o.id) : [])
    },
    [options, onChange]
  )

  return {
    indeterminate,
    allChecked,
    handleCheckAllChange,
    handleChange,
  }
}

export default useMultiSelect
