import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CHANNELS_FORWRADING_INSTRUCTIONS } from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import {
  useChannel,
  useRedirectToChannel,
  useRebuildLeftNavMenu,
} from 'ducks/channels/hooks'
import { selectIsInInbox } from 'selectors/location'
import { selectChannelCreationStepCount } from 'ducks/channels/selectors'
import animatedMailbox from 'assets/mailbox.gif'
import { useAdminAccess } from '../../NoAccess/hooks'
import ForwardingActions from './ForwardingActions'
import ForwardingGuides from './ForwardingGuides'

import { styles } from './styles'
import { CHANNEL_TYPES } from '../Channels.data'

const EmailForward = ({
  drawerResourceId: channelId,
  drawerChannelType: channelType = 'forwarding',
  onClose,
  onExit,
  previousDrawer,
}) => {
  const { channel, isMissing } = useChannel(channelId, {
    // Only refetch the channel during creation steps
    // so can show forwardEmailAddress directly without reloading if this is the first step
    useCachedIfAvailable: !previousDrawer,
  })

  const { state, forward_email_address: forwardEmailAddress } = channel || {}
  const isInInbox = useSelector(selectIsInInbox)
  const creationStepCount = useSelector(storeState =>
    selectChannelCreationStepCount(storeState, CHANNEL_TYPES[channelType])
  )
  const { rebuildExitAndRedirect } = useRedirectToChannel({
    channelId,
    onExit,
  })
  const { rebuildMenuAndExit } = useRebuildLeftNavMenu({ channelId, onExit })
  useAdminAccess(onClose)

  const handleOnExit = useCallback(
    () => {
      // we do not rebuild left nav as soon as channel is created anymore
      // if the user clicks the Exit button on top right, rebuild nav
      if (isInInbox) {
        rebuildExitAndRedirect()
        return
      }
      rebuildMenuAndExit()
    },
    [isInInbox, rebuildMenuAndExit, rebuildExitAndRedirect]
  )

  useEffect(
    () => {
      if (isMissing) onClose()
    },
    [isMissing, onClose]
  )

  useEffect(() => {
    // Preload the mailbox gif for ForwardingTest, so can show the gif immediately
    const maiboxImage = document.createElement('img')
    maiboxImage.src = animatedMailbox
  }, [])

  const {
    drawerId: checkInstructionsDrawerId,
    openDrawer: openCheckInstructionsDrawer,
  } = useDrawer({
    id: null,
    type: DRAWER_TYPE_CHANNELS_FORWRADING_INSTRUCTIONS,
    closeIgnoresStack: false,
  })

  const handleCheckInstructions = useCallback(
    () => {
      openCheckInstructionsDrawer(channelId, {
        query: {
          ...buildDrawerQueryParam(
            checkInstructionsDrawerId,
            'drawerChannelType',
            channelType
          ),
        },
      })
    },
    [
      openCheckInstructionsDrawer,
      channelId,
      checkInstructionsDrawerId,
      channelType,
    ]
  )

  const openWidgetContactForm = useCallback(async e => {
    e.preventDefault()
    if (window?.groove?.widget) {
      await window.groove.widget.updateSettings({
        iconPosition: 'left',
      })
      // push route before opening to prevent the flicker from home screen to the new route
      window.groove.widget.pushRoute('/contact')
      window.groove.widget.open()
    }
  }, [])

  return (
    <Modal
      dataTestId="channels-forwarding-verification"
      portal="#drawer-root"
      onClose={handleOnExit}
      open
      backdropTransparency="light"
    >
      <ProgressDots
        count={creationStepCount}
        now={3}
        className="grui mt-4 mb-13"
      />
      <Modal.Title>
        {previousDrawer
          ? 'Setup your forwarding info'
          : 'Forward your emails to Groove'}
      </Modal.Title>
      <Modal.Description>
        To start receiving your email in your Groove account, you need to setup
        forwarding.
      </Modal.Description>
      <div css={styles.content} className="grui text-center">
        <Modal.CopyField
          key={forwardEmailAddress}
          defaultValue={forwardEmailAddress}
          className="grui mt-12"
        />
        <Modal.Instructions
          title="Need a hand in getting setup?"
          className="grui mt-11 text-left"
        >
          Quickly{` `}
          <button
            css={button.styles.link}
            type="button"
            onClick={handleCheckInstructions}
          >
            send instructions to a teammate
          </button>
          {` `}to get your email address connected or{` `}
          {/* Use a tag so can break the words */}
          <a css={button.styles.link} onClick={openWidgetContactForm}>
            chat to one of our support specialists
          </a>.
        </Modal.Instructions>
        <ForwardingActions
          className="grui mt-16"
          onSetupLater={handleOnExit}
          onExit={onExit}
          channelId={channelId}
          channelType={channelType}
          channelState={state}
        />
      </div>
      <ForwardingGuides />
    </Modal>
  )
}

export default EmailForward
