import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Field from '@groovehq/internal-design-system/lib/components/Field/Field'
import MessageCard from '@groovehq/internal-design-system/lib/components/MessageCard/MessageCard'
import AnimatedEllipsis from '@groovehq/internal-design-system/lib/components/AnimatedEllipsis/AnimatedEllipsis'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'

import { styles as FieldStyles } from '@groovehq/internal-design-system/lib/components/Field/Field.styles'
import * as yup from 'yup'
import { useDrawer } from 'ducks/drawers/hooks'
import { DRAWER_TYPE_CHANNELS_FORWRADING_INSTRUCTIONS_SENT } from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { useChannel } from 'ducks/channels/hooks'
import { selectCurrentUser } from 'ducks/currentUser/selectors/selectCurrentUser'
import { doSendSetupInstructions } from 'ducks/mailboxes/actions'
import { selectChannelCreationStepCount } from 'ducks/channels/selectors'
import { styles } from './styles'
import { useAdminAccess } from '../../NoAccess/hooks'
import { CHANNEL_TYPES } from '../Channels.data'

const buildEmailBody = (channel, fromName) => `
  <div
    style="
      font-size: 13px;
      line-height: 1.5;
      color: #1b1b1b;
      letter-spacing: 0;
    "
  >
    Hi,
    <br />
    <br />
    We&apos;ve just setup our account with Groove and need your assistance in
    setting up email forwarding from our current email provider to
    Groove.
    <br />
    <br />
    All emails should be forwarded to this address:
    <br />
    <a
      href="mailto:${channel?.forward_email_address || ''}"
      style="
        color: #129eff;
        font-weight: 500;
        text-decoration: none;
      "
    >
    ${channel?.forward_email_address || ''}
    </a>
    <br />
    <br />
    Please let me know once completed.
    <br />
    <br />
    Thanks,
    <br />
    ${fromName}
  </div>
`

const FORM_STATE_EMPTY = {
  recipient: '',
}

const FORM_SCHEMA = yup.object().shape({
  recipient: yup
    .string()
    .email('Email address is invalid')
    .required('Recipient is required'),
})

const ForwardingInstructions = ({
  drawerResourceId: channelId,
  drawerChannelType: channelType,
  onExit,
  onClose,
}) => {
  const dispatch = useDispatch()
  const [isSaving, setIsSaving] = useState(null)
  const [errors, setErrors] = useState(null)
  const currentUser = useSelector(selectCurrentUser)
  const creationStepCount = useSelector(storeState =>
    selectChannelCreationStepCount(storeState, CHANNEL_TYPES[channelType])
  )
  const { channel, isLoading, isMissing } = useChannel(channelId)
  useAdminAccess(onExit)

  const emailBody = useMemo(
    () => {
      const {
        first_name: currentUserFirstName = '',
        last_name: currentUserLastName = '',
      } =
        currentUser || {}

      return buildEmailBody(
        channel,
        `${currentUserFirstName || ''} ${currentUserLastName || ''}`
      )
    },
    [channel, currentUser]
  )

  useEffect(
    () => {
      if (isMissing) onExit()
    },
    [isMissing, onExit]
  )

  const { drawerId: nextDrawerId, openDrawer: openNextDrawer } = useDrawer({
    id: null,
    type: DRAWER_TYPE_CHANNELS_FORWRADING_INSTRUCTIONS_SENT,
    closeIgnoresStack: false,
  })

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(FORM_SCHEMA),
    defaultValues: { ...FORM_STATE_EMPTY },
  })

  const handleBack = useCallback(
    () => {
      onClose({ ignoreStack: false })
    },
    [onClose]
  )

  const onSubmit = useCallback(
    async payload => {
      try {
        setIsSaving(true)
        setErrors(null)
        await dispatch(doSendSetupInstructions(channelId, payload))
        setIsSaving(false)
        openNextDrawer(channelId, {
          query: {
            ...buildDrawerQueryParam(
              nextDrawerId,
              'drawerChannelType',
              channelType
            ),
            ...buildDrawerQueryParam(nextDrawerId, 'drawerInstructionsSent', 1),
            ...buildDrawerQueryParam(
              nextDrawerId,
              'drawerInstructionsEmail',
              payload.recipient
            ),
          },
        })
      } catch (error) {
        setIsSaving(false)
        setErrors(error)
      }
    },
    [dispatch, openNextDrawer, nextDrawerId, channelId, channelType]
  )

  const footer = (
    <>
      <Modal.Button
        type="info"
        htmlType="submit"
        disabled={!isValid || isSaving}
        className="grui mt-18"
        css={styles.oauthBtn}
      >
        <>
          {!isSaving && <span>Send instructions &amp; close</span>}
          {isSaving && (
            <span>
              Sending<AnimatedEllipsis />
            </span>
          )}
        </>
      </Modal.Button>
      <MessageCard
        type="negative"
        closeable
        className="grui my-10"
        visible={!!errors}
      >
        An error occurred sending the forwarding instructions. Please try again.
      </MessageCard>
      <div className="grui text-center mt-11">
        <Modal.Button type="link" onClick={handleBack} disabled={isSaving}>
          Back
        </Modal.Button>
      </div>
    </>
  )

  return (
    <Modal
      onClose={onExit}
      portal="#drawer-root"
      open
      isLoading={isLoading}
      backdropTransparency="light"
    >
      <ProgressDots
        count={creationStepCount}
        now={3}
        className="grui mt-4 mb-13"
      />
      <Modal.Title>Send instructions</Modal.Title>
      <Modal.Description>
        To start receiving your email in your Groove account, you need to setup
        forwarding.
      </Modal.Description>
      <div css={styles.content} className="grui pt-12 pb-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Field
              label="Recipient"
              placeholder="Enter email address…"
              css={styles.fullWidth}
              validateStatus={formErrors?.recipient ? 'error' : undefined}
              help={formErrors?.recipient?.message}
              {...register('recipient')}
            />
            <div css={FieldStyles.labelBox} className="grui mt-14">
              Email
            </div>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: emailBody,
              }}
              className="grui p-7 pb-11"
              css={styles.emailBody}
            />
          </div>
          {footer}
        </form>
      </div>
    </Modal>
  )
}

export default ForwardingInstructions
