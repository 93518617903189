import { selectCurrentPage } from 'selectors/location'
import { createSelector } from 'reselect'
import { SETTINGS_BILLING_PAGE } from 'subapps/settings/types'

export const selectIsBillingPage = createSelector(
  selectCurrentPage,
  currentPage => {
    return currentPage === SETTINGS_BILLING_PAGE
  }
)
