import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { button } from '@groovehq/internal-design-system/lib/styles/elements'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'
import { useDrawer } from 'ducks/drawers/hooks'
import {
  DRAWER_TYPE_CHANNELS_FORWARDING_DETAILS,
  DRAWER_TYPE_CHANNELS_FORWRADING_TEST,
} from 'ducks/drawers/types'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import {
  useChannel,
  useRedirectToChannel,
  useRebuildLeftNavMenu,
} from 'ducks/channels/hooks'
import { selectChannelCreationStepCount } from 'ducks/channels/selectors'
import { doBuildInboxMenuFromMailboxes } from 'ducks/folders/operations/collections'
import { doOpenSearchPageWithMailboxAndFolder } from 'ducks/channels/actions'
import { selectIsInInbox } from 'selectors/location'
import { useAdminAccess } from '../../NoAccess/hooks'
import ForwardingActions from '../EmailForward/ForwardingActions'
import ForwardingGuides from '../EmailForward/ForwardingGuides'

import { styles } from './styles'
import { CHANNEL_TYPES } from '../Channels.data'

function isConfirmed(state) {
  return ['active', 'confirmed'].includes(state)
}

const ForwardingFailinformation = ({
  drawerResourceId: channelId,
  drawerChannelType: channelType = 'forwarding',
  onClose,
  onExit,
  drawerChannelHasConfirmationCode: hasConfirmationCode,
}) => {
  const dispatch = useDispatch()

  const isInInbox = useSelector(selectIsInInbox)
  const creationStepCount = useSelector(storeState =>
    selectChannelCreationStepCount(storeState, CHANNEL_TYPES[channelType])
  )
  const { channel, isLoading, isMissing } = useChannel(channelId)

  const { state, forward_email_address: forwardEmailAddress, email, folders } =
    channel || {}

  const { rebuildExitAndRedirect } = useRedirectToChannel({
    channelId,
    onExit,
  })

  const { rebuildMenuAndExit } = useRebuildLeftNavMenu({ channelId, onExit })

  useAdminAccess(onClose)

  useEffect(
    () => {
      if (isMissing) onClose()
    },
    [isMissing, onClose]
  )

  const {
    drawerId: openChannelDetailsId,
    openDrawer: openChannelDetails,
  } = useDrawer({
    id: null,
    type: DRAWER_TYPE_CHANNELS_FORWARDING_DETAILS,
    closeIgnoresStack: false,
  })

  const {
    drawerId: forwardingTestDrawerId,
    openDrawer: openForwadingTestModal,
  } = useDrawer({
    id: null,
    type: DRAWER_TYPE_CHANNELS_FORWRADING_TEST,
    closeIgnoresStack: false,
  })

  const handleOnExit = useCallback(
    () => {
      // we do not rebuild left nav as soon as channel is created anymore
      // if the user clicks the Exit button on top right, rebuild nav
      if (isInInbox) {
        rebuildExitAndRedirect()
        return
      }
      rebuildMenuAndExit()
    },
    [isInInbox, rebuildMenuAndExit, rebuildExitAndRedirect]
  )

  const handleOpenChannelDetails = useCallback(
    () => {
      openChannelDetails(channelId, {
        query: {
          ...buildDrawerQueryParam(
            openChannelDetailsId,
            'drawerChannelType',
            channelType
          ),
        },
      })
    },
    [openChannelDetails, channelId, openChannelDetailsId, channelType]
  )

  const handleRebuildExitAndRedirect = useCallback(
    () => {
      dispatch(doBuildInboxMenuFromMailboxes())
      onExit()
      dispatch(
        doOpenSearchPageWithMailboxAndFolder(
          channelId,
          folders ? folders[0].id : null
        )
      )
    },
    [dispatch, onExit, channelId, folders]
  )

  const onSaveBtnClick = useCallback(
    () => {
      if (isConfirmed(state)) {
        if (isInInbox) handleRebuildExitAndRedirect()
        return
      }
      openForwadingTestModal(channelId, {
        query: {
          ...buildDrawerQueryParam(
            forwardingTestDrawerId,
            'drawerChannelType',
            channelType
          ),
          ...buildDrawerQueryParam(
            forwardingTestDrawerId,
            'drawerChannelHasConfirmationCode',
            1
          ),
        },
      })
    },
    [
      isInInbox,
      handleRebuildExitAndRedirect,
      state,
      channelId,
      channelType,
      openForwadingTestModal,
      forwardingTestDrawerId,
    ]
  )

  return (
    <Modal
      dataTestId="channels-forwarding-verification"
      portal="#drawer-root"
      onClose={handleOnExit}
      isLoading={isLoading}
      open
      backdropTransparency="light"
    >
      <ProgressDots
        count={creationStepCount}
        now={3}
        className="grui mt-4 mb-13"
      />
      <Modal.Title>
        {hasConfirmationCode
          ? 'No confirmation email received'
          : 'Oh no, we didn’t receive the test!'}
      </Modal.Title>
      <Modal.Description>
        {hasConfirmationCode
          ? `We didn’t receive any confirmation email for your ${app.t(
              'mailbox'
            )}. Check your details and see if they correct.`
          : 'To start receiving your email in your Groove account, you need to setup forwarding.'}
      </Modal.Description>
      <div css={styles.content} className="grui text-center">
        <Modal.CopyField
          key={forwardEmailAddress}
          defaultValue={forwardEmailAddress}
          className="grui mt-12"
        />
        <Modal.Instructions
          title="Correct email address?"
          className="grui mt-11 text-left"
          css={styles.instructions}
        >
          <span className="grui tilt-highlight px-1 truncate mw-100">
            {email}
          </span>
          <br />
          We are sending the test email to this address. If this is the
          {` `}incorrect address, you can{` `}
          <button
            css={button.styles.link}
            type="button"
            onClick={handleOpenChannelDetails}
          >
            change it here
          </button>.
        </Modal.Instructions>
        {!hasConfirmationCode && (
          <ForwardingActions
            className="grui mt-16"
            onSetupLater={handleOnExit}
            onExit={onExit}
            channelId={channelId}
            channelType={channelType}
            channelState={state}
          />
        )}
      </div>
      {hasConfirmationCode && (
        <div className="grui text-center w-100 mt-24">
          <Modal.Button
            type="info"
            data-test-id="forwarding-details-form-continue-button"
            onClick={onSaveBtnClick}
          >
            Test again
          </Modal.Button>
          <Modal.Button
            type="link"
            className="grui mt-11"
            onClick={handleOnExit}
          >
            I&apos;ll do this later
          </Modal.Button>
        </div>
      )}
      <ForwardingGuides />
    </Modal>
  )
}

export default ForwardingFailinformation
