import React, { useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'
import {
  useChannel,
  useRedirectToChannel,
  useRebuildLeftNavMenu,
} from 'ducks/channels/hooks'
import { DRAWER_TYPE_CHANNELS_FORWRADING_INSTRUCTIONS } from 'ducks/drawers/types'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam } from 'ducks/drawers/util'
import { selectIsInInbox } from 'selectors/location'
import { selectChannelCreationStepCount } from 'ducks/channels/selectors'
import { styles } from './styles'
import { CHANNEL_TYPES } from '../Channels.data'

const ForwardingInstructionsSent = ({
  onExit,
  drawerChannelType: channelType,
  drawerResourceId: channelId,
  drawerInstructionsEmail: instructionsEmail,
}) => {
  const { isLoading, isMissing } = useChannel(channelId)
  const isInInbox = useSelector(selectIsInInbox)
  const creationStepCount = useSelector(storeState =>
    selectChannelCreationStepCount(storeState, CHANNEL_TYPES[channelType])
  )

  const { rebuildExitAndRedirect } = useRedirectToChannel({
    channelId,
    onExit,
  })

  const { rebuildMenuAndExit } = useRebuildLeftNavMenu({ channelId, onExit })

  const {
    drawerId: checkInstructionsDrawerId,
    openDrawer: openCheckInstructionsDrawer,
  } = useDrawer({
    id: null,
    type: DRAWER_TYPE_CHANNELS_FORWRADING_INSTRUCTIONS,
    closeIgnoresStack: false,
  })

  const handleOnExit = useCallback(
    () => {
      // we do not rebuild left nav as soon as channel is created anymore
      // if the user clicks the Exit button on top right, rebuild nav
      if (isInInbox) {
        rebuildExitAndRedirect()
        return
      }
      rebuildMenuAndExit()
    },
    [isInInbox, rebuildMenuAndExit, rebuildExitAndRedirect]
  )

  const handleCheckInstructions = useCallback(
    () => {
      openCheckInstructionsDrawer(channelId, {
        query: {
          ...buildDrawerQueryParam(
            checkInstructionsDrawerId,
            'drawerChannelType',
            channelType
          ),
        },
      })
    },
    [
      openCheckInstructionsDrawer,
      channelId,
      checkInstructionsDrawerId,
      channelType,
    ]
  )

  useEffect(
    () => {
      if (isMissing) onExit()
    },
    [isMissing, onExit]
  )

  return (
    <Modal
      isLoading={isLoading}
      onClose={onExit}
      portal="#drawer-root"
      className="grui pb-14"
      open
      backdropTransparency="light"
    >
      <ProgressDots
        count={creationStepCount}
        now={creationStepCount}
        className="grui mt-4 mb-13"
      />
      <Modal.Title>Forwarding instructions sent!</Modal.Title>
      <Modal.Description>
        Great news! We&apos;ve sent the setup instructions to{' '}
        <strong>{instructionsEmail}</strong> with all the details to setup the
        email forwarding!
      </Modal.Description>
      <div />
      <div css={styles.content} className="grui mt-11">
        <Modal.Instructions title="Just a reminder">
          You will not receive emails to this email address in Groove until{` `}
          email forwarding is setup.
        </Modal.Instructions>
        <div className="grui mt-12 text-center">
          <Modal.Button
            type="info"
            onClick={handleOnExit}
            as="a"
            css={styles.fullWidth}
            className="grui mt-10 mb-5"
          >
            Close
          </Modal.Button>
          <Modal.Button
            type="tertiary"
            onClick={handleCheckInstructions}
            as="a"
            css={styles.fullWidth}
          >
            Send to another {app.t('agent')}
          </Modal.Button>
        </div>
      </div>
    </Modal>
  )
}

export default ForwardingInstructionsSent
