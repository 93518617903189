import React, { useCallback, useMemo, useState } from 'react'
import Dropdown from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { emptyArr } from 'util/arrays'
import {
  Footer,
  styles as multiSelectMenuStyles,
  useMultiSelect,
} from '../MultiSelectMenu'
import MenuWithSearch, {
  useShowHeaderShadow,
  styles as menuWithSearchStyles,
} from '../MenuWithSearch'
import { styles } from './styles'

const statuses = [
  {
    id: '1000',
    name: 'Unread',
  },
  {
    id: '2000',
    name: 'Open',
  },
  {
    id: '4000',
    name: 'Snoozed',
  },
  {
    id: '5000',
    name: 'Closed',
  },
  {
    id: '7000',
    name: 'Trash',
  },
  {
    id: '6000',
    name: 'Spam',
  },
]

const StatusesDropdown = ({
  value: inputValue,
  setValue,
  selectedIds,
  onSelectedIdsChange,
}) => {
  const value = inputValue || emptyArr
  const [menuVisible, setMenuVisible] = useState(false)
  const { handleChange } = useMultiSelect(
    statuses,
    onSelectedIdsChange,
    selectedIds
  )
  const { handleScroll, shouldShowHeaderShadow } = useShowHeaderShadow(
    menuVisible
  )

  const handleMenuVisibleChange = useCallback(visible => {
    setMenuVisible(visible)
  }, [])
  const handleSelect = useCallback(
    () => {
      setValue(selectedIds)
      setMenuVisible(false)
    },
    [setValue, selectedIds]
  )

  const handleCancel = useCallback(() => {
    setMenuVisible(false)
  }, [])

  const menu = useMemo(
    () => {
      return (
        <MenuWithSearch onScroll={handleScroll}>
          <div className="grui pt-3 pb-5">
            {statuses.map(({ name, id }) => (
              <MenuWithSearch.Item itemKey={id} key={id}>
                <Checkbox
                  css={multiSelectMenuStyles.checkbox}
                  truncate
                  id={id}
                  onChange={handleChange}
                  checked={selectedIds.some(itemId => itemId === id)}
                >
                  <div className="grui truncate">{name}</div>
                </Checkbox>
              </MenuWithSearch.Item>
            ))}
          </div>
        </MenuWithSearch>
      )
    },
    [selectedIds, handleChange, handleScroll]
  )
  const footer = useMemo(
    () => (
      <Footer
        onSelect={handleSelect}
        onCancel={handleCancel}
        saveBtnTitle={`Select statuses`}
      />
    ),
    [handleSelect, handleCancel]
  )

  const dropdownTitle = useMemo(
    () => {
      if (
        statuses.length &&
        statuses.length === value.filter(v => v !== '0').length
      ) {
        return `All statuses`
      }

      const filteredValues = value.filter(v => !!v)

      if (filteredValues.length) {
        return filteredValues
          .map(id => statuses.find(s => s.id === id).name)
          .join(', ')
      }

      return `Select statuses`
    },
    [value]
  )

  return (
    <Dropdown
      overlay={menu}
      footer={footer}
      css={[
        text.styles.textSm,
        multiSelectMenuStyles.dropdownContainer,
        shouldShowHeaderShadow && menuWithSearchStyles.showHeaderShadow,
      ]}
      visible={menuVisible}
      onVisibleChange={handleMenuVisibleChange}
      emptyHint=""
      autoHeight
    >
      <Dropdown.Button
        css={[multiSelectMenuStyles.dropdownBtn, styles.dropdownBtn]}
        size="small"
      >
        {dropdownTitle}
      </Dropdown.Button>
    </Dropdown>
  )
}

export default StatusesDropdown
