import {
  MESSAGE,
  SNOOZE_STATE,
  UNSNOOZE_STATE,
  STATE,
  AGENT,
  GROUP,
  AGENT_AND_GROUP,
  TICKET_MERGER,
  RATING,
  FOLLOW,
  LABEL,
  TICKET_CUSTOMER_ACTION_OPEN,
  COLLAPSED_CHANGESET,
  DELETED,
  STAR,
  COMMENT_DELETION,
  COMMENT_EDIT,
  COMMENT_REACTION,
} from 'constants/changesetActionChangeTypes'
import { reduceActor } from 'util/actors'
import { formatTime, strftime, isThisYear, toDate } from 'util/date'
import {
  isMessage,
  isForward,
  isAgentResponse,
  isNote,
  embedAttachments,
  parseBody,
  isUserChange,
  isReply,
} from 'util/changesets'
import { ellipsify, unsafeStripTags } from 'util/strings'
import { priorityMap } from 'util/folders'
import { without } from 'util/arrays'
import { findAddedReactionAction } from 'ducks/comments/util'

const sortActionsOrder = [
  TICKET_MERGER,
  MESSAGE,
  SNOOZE_STATE,
  UNSNOOZE_STATE,
  STATE,
  AGENT,
  GROUP,
]
const sortActions = actions => {
  return actions.sort((a, b) => {
    const aIndex = sortActionsOrder.indexOf(a.change_type)
    const bIndex = sortActionsOrder.indexOf(b.change_type)
    return aIndex - bIndex
  })
}

const actionToRedundantNextAction = new Map([
  [SNOOZE_STATE, STATE],
  [UNSNOOZE_STATE, STATE],
  [AGENT, GROUP],
  [DELETED, STATE],
])

function getDateString(date) {
  const format = isThisYear(date) ? '%b %d' : '%b %d, %Y'
  return strftime(format, date)
}

function reduceActionDateTime(action) {
  const datetime = toDate(action.created_at)
  return [getDateString(datetime), '@', formatTime(datetime)].join(' ')
}

function isTicketActionChangeBodyEmpty(body) {
  return (
    !body || body === '<em>Empty</em>' || body === '&lt;em&gt;Empty&lt;/em&gt;'
  )
}

function isCustomerReply(action) {
  return (
    isMessage(action) && !isForward(action) && !isAgentResponse(action.change)
  )
}

export function getUpdatedTicketBodyAttributes(action) {
  const data = {}

  const actor = action.actor
  const change = action.change
  const body = change.body || ''
  const snippet = ellipsify(unsafeStripTags(body), 197)

  data.body = snippet

  if (change.note) {
    data.bodyType = 'internal'
  } else if (change.forward || change.conversationType === 'third_party') {
    data.bodyType = 'third_party'
  } else {
    data.bodyType = 'enduser'
  }

  if (actor) {
    data.bodyAuthor = {
      id: actor.id,
      type: actor.type,
    }
  }

  return data
}

function applyChangesToTicketActions(actions, changes) {
  if (!changes) {
    return actions
  }

  const newActions = []

  actions.forEach(action => {
    const change = changes[action.change?.id]
    const newAction = { ...action }

    if (!change) {
      newActions.push(newAction)
      return
    }

    if (change.remove) {
      return
    }

    if (newAction.change_type === MESSAGE) {
      if (change.body) newAction.change.body = change.body
    }

    newActions.push(newAction)
  })

  return newActions
}

export function buildMessageCollection(actions) {
  // bundle up actions into changesets
  let ticketActions = actions.records
  const changes = actions.recordChanges
  const oldCollection = actions.oldCollection
  const messages = []
  let currentMessage = null
  const newActions = []
  let lastNote = null
  let lastMessage = null
  let lastReply = null
  let lastCustomerReply = null
  let firstReply = null
  let firstMessage = null
  let noteCount = 0
  let messageCount = 0
  let totalMergedMessageCount = 0
  let currentMergedMessageCount = 0
  let hasUserChanged = false
  const reactions = oldCollection?.reactions ? [...oldCollection.reactions] : []
  const computedReactions = {}
  const oldMessages = oldCollection?.messages || []

  const changesets = []
  let currentChangeset = { id: null, actions: [], isFromMerge: false }
  const readReceipts = {}

  const undos = {}

  ticketActions = applyChangesToTicketActions(ticketActions, changes)

  ticketActions.forEach(ticketAction => {
    if (
      ticketAction.change_type === COMMENT_DELETION &&
      ticketAction.changeset.includes('optimistic-')
    )
      return
    if (ticketAction.change_type === COMMENT_EDIT) return
    if (ticketAction.change_type === RATING) return
    if (ticketAction.change_type === FOLLOW) return
    if (
      ticketAction.change_type === LABEL &&
      ticketAction?.actor?.type !== 'Rule'
    )
      return
    if (ticketAction.change_type === TICKET_CUSTOMER_ACTION_OPEN) {
      readReceipts[ticketAction.change.messageId] = {
        ...ticketAction,
        datetime: reduceActionDateTime(ticketAction),
      }
      return
    }
    if (
      ticketAction.change_type === STATE &&
      ticketAction.actor &&
      ticketAction.actor.type === 'Customer'
    ) {
      return
    }

    if (ticketAction.change_type === 'UndoSend') {
      undos[ticketAction.change.messageChangesetId] = true
    }

    if (ticketAction.change_type === MESSAGE && ticketAction.change.note) {
      const edit = [...ticketActions]
        .reverse()
        .find(
          a =>
            a.change_type === COMMENT_EDIT &&
            a.change.comment_id === ticketAction.change.id &&
            !a.changeset.includes('optimistic-')
        )

      if (edit && ticketAction.change.editedAt !== edit.change.created_at) {
        ticketAction.change.editedAt = edit.change.created_at // eslint-disable-line no-param-reassign
      }
    }

    if (ticketAction.change_type === COMMENT_REACTION) {
      const existingAction = reactions.find(
        action => action.id === ticketAction.id
      )

      if (!existingAction) {
        reactions.push(ticketAction)
      }

      return
    }

    if (!ticketAction.change) {
      return
    }

    // Ensure that `to` is an array, for compatibility
    if (ticketAction.change.to && !Array.isArray(ticketAction.change.to)) {
      /* eslint-disable-next-line no-param-reassign */
      ticketAction.change.to = [ticketAction.change.to]
    }
    // This flag controls if currently processed changeset should get
    // added to the resulting root changeset list
    // If a changeset gets added into a subgroup, we might want to skip
    // adding it to the root collection
    let addToRootChangesets = false
    if (isMessage(ticketAction)) {
      lastMessage = ticketAction
      if (!firstMessage) firstMessage = ticketAction
      messageCount += 1
    }
    if (isNote(ticketAction)) {
      lastNote = ticketAction
      noteCount += 1
    }
    if (isUserChange(ticketAction)) hasUserChanged = true

    if (ticketAction.changeset !== currentChangeset.id) {
      const changesetId = ticketAction.changeset
      currentChangeset = {
        id: changesetId,
        realId: changesetId && changesetId.replace('-collapsed', ''),
        actions: [],
        isFromMerge: false,
      }
      addToRootChangesets = true
    }

    if (ticketAction.change_type === TICKET_MERGER) {
      currentChangeset.isFromMerge = true
      totalMergedMessageCount = ticketAction.preview[0]
      currentMergedMessageCount = totalMergedMessageCount
    }

    if (
      ticketAction.change_type === MESSAGE ||
      ticketAction.change_type === COLLAPSED_CHANGESET
    ) {
      if (
        totalMergedMessageCount > 0 &&
        currentMergedMessageCount === totalMergedMessageCount
      ) {
        currentChangeset.firstMergedTicketActionCreatedAt =
          ticketAction.created_at
      }
      if (currentMergedMessageCount) {
        currentChangeset.isFromMerge = true
        currentMergedMessageCount -= 1
        if (totalMergedMessageCount > 0 && currentMergedMessageCount === 0) {
          currentChangeset.isLastFromMerge = true
          totalMergedMessageCount = 0
        }
      }
    }

    currentChangeset.actions.push(ticketAction)

    if (addToRootChangesets) changesets.push(currentChangeset)
  })

  /* eslint-disable no-param-reassign */
  changesets.forEach((changeset, idx) => {
    changeset.index = idx + 1
    changeset.actions = sortActions(changeset.actions)
    changeset.actions[
      changeset.actions.length - 1
    ].isLastSubsequentAction = true
  })
  /* eslint-enable no-param-reassign */

  changesets.forEach(changeset => {
    if (undos[changeset.id]) return
    let skipNextType
    let prevChangesetId
    changeset.actions.forEach(action => {
      const type = action.change_type
      if (!action.isEnhanced) {
        /* eslint-disable-next-line no-param-reassign */
        action = Object.assign({}, action, {
          isEnhanced: true,
          actor: action.actor && reduceActor(action.actor),
          datetime: reduceActionDateTime(action),
        })
      }
      newActions.push(action)
      if (type === COLLAPSED_CHANGESET) {
        const change = action.change
        const id = action.changeset.replace('-collapsed', '')

        const actorType = action.actor && action.actor.type

        currentMessage = {
          id,
          action,
          loaded: false,
          subsequentActions: [],
          isFromMerge: change.is_from_merge,
          isNote: change.is_note,
          isForward: change.is_forward,
          isOutsideCommunication:
            change.is_forward || actorType === 'Collaborator',
          hasAttachments: change.has_attachments,
          isBodyEmpty: isTicketActionChangeBodyEmpty(change.snippet),
          snippet: change.snippet,
          hasRawEmail: change.hasRawEmail,
        }
        messages.push(currentMessage)
      } else if (type === MESSAGE) {
        const change = action.change

        const { parts, attachments } = change
        change.isBodyEmpty = isTicketActionChangeBodyEmpty(change.body)
        if (!action.parsedBody) {
          change.parsedBody =
            parts && parts.length > 0
              ? embedAttachments(parts, attachments)
              : embedAttachments(parseBody(change.body), attachments)
          change.isBodyEmpty =
            change.isBodyEmpty ||
            !change.parsedBody ||
            !change.parsedBody.find(part => {
              // if the part has an image, consider it non-empty
              if (part.text && part.text.match('<img')) return true
              return !!unsafeStripTags(part.text).trim()
            })
        }
        const messageId = action.change.id

        const id = action.changeset

        const actorType = action.actor && action.actor.type

        currentMessage = {
          id,
          action,
          loaded: true,
          subsequentActions: [],
          isNote: change.note,
          isForward: change.forward,
          isOutsideCommunication:
            change.forward || actorType === 'Collaborator',
          body: change.body,
          hasRawEmail: change.hasRawEmail,
          isFromMerge: changeset.isFromMerge,
          hasAttachments: !!attachments && attachments.length > 0,
          snippet: ellipsify(unsafeStripTags(change.body), 500),
          readReceipt:
            readReceipts[messageId] ||
            // we need to keep the read receipt from the old messages
            // because the read action is not in the current actions if fetching actions for an expanded message
            oldMessages.find(message => message.id === id)?.readReceipt,
        }
        if (isReply(action)) {
          if (!firstReply) firstReply = action
          lastReply = action
        }
        if (isCustomerReply(action)) {
          lastCustomerReply = action
        }
        messages.push(currentMessage)
      } else if (currentMessage) {
        const {
          changeset: actionChangeset,
          actor: { type: actorType } = {},
        } = action

        if (
          (type === STATE ||
            type === AGENT ||
            type === GROUP ||
            type === AGENT_AND_GROUP) &&
          actorType === 'Customer'
        ) {
          // don't use state/agent/group changes which are results of customer actions
          return
        }

        if (type === STAR && action?.change?.priority !== priorityMap.urgent) {
          // we do not show non-starred priority changes any more
          return
        }

        if (
          skipNextType &&
          type &&
          skipNextType === type &&
          prevChangesetId === actionChangeset
        ) {
          // is redundant following action we don't need to show the customer
          // e.g. snoozing a ticket has 'Snoozed' action then 'Closed' action afterwards (Closed action is redundant customer info)
          // for now just a simple map, but if it grows more complicated should prob be it's own module/api driven
          return
        }
        // it's fine if skipNextType value is undefined
        skipNextType = actionToRedundantNextAction.get(type)
        prevChangesetId = actionChangeset

        currentMessage.subsequentActions.push(action)
      }
    })
  })

  reactions.forEach(action => {
    if (!computedReactions[action.change.comment_id]) {
      computedReactions[action.change.comment_id] = {}
    }

    if (!computedReactions[action.change.comment_id][action.change.reaction]) {
      computedReactions[action.change.comment_id][action.change.reaction] = []
    }

    if (!action.change.is_added) {
      const addedReactionAction = findAddedReactionAction(
        computedReactions,
        action.change.comment_id,
        action.actor.id,
        action.change.reaction
      )

      if (addedReactionAction) {
        without(
          computedReactions[action.change.comment_id][action.change.reaction],
          addedReactionAction
        )
      }

      return
    }

    computedReactions[action.change.comment_id][action.change.reaction].push(
      action
    )
  })

  return {
    messages,
    actions: newActions,
    changesets,
    lastNote,
    lastMessage,
    lastReply,
    lastCustomerReply,
    firstReply,
    firstMessage,
    hasUserChanged,
    noteCount,
    messageCount,
    reactions,
    computedReactions,
  }
}
