import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import Dropdown, {
  divider,
} from '@groovehq/internal-design-system/lib/components/Dropdown/Dropdown'
import Checkbox from '@groovehq/internal-design-system/lib/components/Checkbox/Checkbox'
import Avatar from '@groovehq/internal-design-system/lib/components/Avatar/Avatar'
import { text } from '@groovehq/internal-design-system/lib/styles/elements'
import { emptyArr } from 'util/arrays'
import { selectCurrentPaidActiveAgentsWithCurrentUserSort } from 'ducks/agents/selectors'
import {
  Footer,
  styles as multiSelectMenuStyles,
  useMultiSelect,
} from '../MultiSelectMenu'
import MenuWithSearch, {
  useShowHeaderShadow,
  styles as menuWithSearchStyles,
} from '../MenuWithSearch'
import { styles } from './styles'
import Agents from './Agents'

const CURRENT_USER_ID = '-1'
const ASSIGNED_ID = '0'

const TeammatesDropdown = ({
  value: inputValue,
  setValue,
  selectedIds,
  onSelectedIdsChange,
  allowSelectAssigned = false,
  allowSelectCurrentUser = false,
  operator,
}) => {
  const searchRef = useRef()
  const value = inputValue || emptyArr
  const agents = useSelector(selectCurrentPaidActiveAgentsWithCurrentUserSort)
  const [menuVisible, setMenuVisible] = useState(false)
  const [filteredList, setFilteredList] = useState([])
  const [search, setSearch] = useState('')
  const { handleCheckAllChange, handleChange } = useMultiSelect(
    filteredList,
    onSelectedIdsChange,
    selectedIds
  )
  const { handleScroll, shouldShowHeaderShadow } = useShowHeaderShadow(
    menuVisible
  )

  const handleMenuVisibleChange = useCallback(visible => {
    setMenuVisible(visible)
  }, [])
  const handleSelect = useCallback(
    () => {
      setValue(selectedIds)
      setMenuVisible(false)
    },
    [setValue, selectedIds]
  )
  const handleCancel = useCallback(() => {
    setMenuVisible(false)
  }, [])
  const handleChangeSearch = useCallback(
    ({ target }) => {
      setSearch(target.value)
      if (target.value?.trim()) {
        const newList = agents.filter(({ name }) =>
          name.toLowerCase().includes(target.value?.trim().toLowerCase())
        )
        setFilteredList(newList)
        onSelectedIdsChange(
          selectedIds.filter(id => newList.some(item => item.id === id))
        )
      } else {
        setFilteredList(agents)
      }
    },
    [agents, onSelectedIdsChange, selectedIds]
  )
  const allChecked = useMemo(
    () => {
      const otherCheckedOptions = [CURRENT_USER_ID, ASSIGNED_ID].filter(
        id => selectedIds.indexOf(id) > -1
      )

      return (
        filteredList.length === selectedIds.length - otherCheckedOptions.length
      )
    },
    [filteredList, selectedIds]
  )
  const indeterminate = useMemo(
    () => {
      const otherCheckedOptions = [CURRENT_USER_ID, ASSIGNED_ID].filter(
        id => selectedIds.indexOf(id) > -1
      )

      return (
        selectedIds.length - otherCheckedOptions.length < filteredList.length &&
        selectedIds.length - otherCheckedOptions.length > 0
      )
    },
    [selectedIds, filteredList]
  )

  const handleAssignedChange = useCallback(
    e => {
      if (e.target.checked) {
        onSelectedIdsChange([ASSIGNED_ID, ...selectedIds])
      } else {
        onSelectedIdsChange(selectedIds.filter(id => id !== ASSIGNED_ID))
      }
    },
    [onSelectedIdsChange, selectedIds]
  )
  const assignedChecked = useMemo(
    () => {
      return selectedIds.indexOf(ASSIGNED_ID) > -1
    },
    [selectedIds]
  )
  const handleCurrentUserChange = useCallback(
    e => {
      if (e.target.checked) {
        onSelectedIdsChange([CURRENT_USER_ID, ...selectedIds])
      } else {
        onSelectedIdsChange(selectedIds.filter(id => id !== CURRENT_USER_ID))
      }
    },
    [onSelectedIdsChange, selectedIds]
  )
  const currentUserChecked = useMemo(
    () => {
      return selectedIds.indexOf(CURRENT_USER_ID) > -1
    },
    [selectedIds]
  )

  const menu = useMemo(
    () => {
      return (
        <MenuWithSearch search={search} onScroll={handleScroll}>
          {allowSelectAssigned && (
            <>
              <Checkbox
                id="assigned-teammate"
                checked={assignedChecked}
                css={text.styles.fontMedium}
                gap={16}
                className="grui px-10 pb-5"
                onChange={handleAssignedChange}
              >
                Assigned {app.t('agent')}
              </Checkbox>
              <br />
            </>
          )}
          {allowSelectCurrentUser && (
            <Checkbox
              id="current-user"
              checked={currentUserChecked}
              css={text.styles.fontMedium}
              gap={16}
              className="grui px-10 pb-5"
              onChange={handleCurrentUserChange}
            >
              Current user
            </Checkbox>
          )}
          {!!filteredList.length && (
            <Checkbox
              id="all-teammates"
              indeterminate={indeterminate}
              checked={allChecked}
              css={text.styles.fontMedium}
              gap={16}
              className="grui px-10 pb-5"
              onChange={handleCheckAllChange}
            >
              All {app.t('agents')}
            </Checkbox>
          )}
          {divider}
          <div className="grui pt-3 pb-5">
            {filteredList.map(({ id, name, avatar_url: avatarUrl }) => (
              <MenuWithSearch.Item itemKey={id} key={id}>
                <Checkbox
                  css={multiSelectMenuStyles.checkbox}
                  truncate
                  id={id}
                  onChange={handleChange}
                  checked={selectedIds.some(itemId => itemId === id)}
                >
                  <div className="grui truncate">
                    <Avatar
                      avatar={avatarUrl}
                      userName={name}
                      className="grui mr-5"
                    />
                    {name}
                  </div>
                </Checkbox>
              </MenuWithSearch.Item>
            ))}
          </div>
        </MenuWithSearch>
      )
    },
    [
      filteredList,
      indeterminate,
      allChecked,
      selectedIds,
      handleChange,
      handleCheckAllChange,
      handleScroll,
      search,
      allowSelectAssigned,
      allowSelectCurrentUser,
      assignedChecked,
      currentUserChecked,
      handleAssignedChange,
      handleCurrentUserChange,
    ]
  )
  const header = useMemo(
    () => (
      <MenuWithSearch.Search
        placeholder={`Search ${app.t('agents')}…`}
        onChange={handleChangeSearch}
        value={search}
        ref={searchRef}
        shouldFocus={menuVisible}
      />
    ),
    [handleChangeSearch, search, menuVisible]
  )

  const footer = useMemo(
    () => (
      <Footer
        onSelect={handleSelect}
        onCancel={handleCancel}
        saveBtnTitle={`Select ${app.t('agents')}`}
      />
    ),
    [handleSelect, handleCancel]
  )

  const drodownTitle = useMemo(
    () => {
      if (
        agents.length &&
        agents.length ===
          value.filter(v => ![CURRENT_USER_ID, ASSIGNED_ID].includes(v)).length
      ) {
        return `All ${app.t('agents')}`
      }

      const filteredValues = value.filter(v => !!v)

      if (filteredValues.length) {
        const selectedAgentList = filteredValues
          .map(id => {
            if ([CURRENT_USER_ID, ASSIGNED_ID].includes(id)) {
              return {
                id,
              }
            }
            return agents.find(item => item.id === id)
          })
          .filter(Boolean)
          .splice(0, 8) // no need to show too many agents cause they will be truncated anyway
        const selectedAgentIdsString = selectedAgentList
          .map(item => item.id)
          .join()
        // Rebuild the agents if key is changed to make sure the overflow calculation is updated
        return (
          <Agents
            list={selectedAgentList}
            key={selectedAgentIdsString}
            operator={operator}
          />
        )
      }

      return `Select ${app.t('agents')}`
    },
    [value, agents, operator]
  )

  useEffect(
    () => {
      if (!search?.trim()) {
        setFilteredList(agents)
      }
    },
    [search, agents]
  )

  return (
    <Dropdown
      overlay={menu}
      header={header}
      footer={footer}
      css={[
        text.styles.textSm,
        multiSelectMenuStyles.dropdownContainer,
        shouldShowHeaderShadow && menuWithSearchStyles.showHeaderShadow,
      ]}
      visible={menuVisible}
      onVisibleChange={handleMenuVisibleChange}
      emptyHint=""
      autoHeight
    >
      <Dropdown.Button
        css={[multiSelectMenuStyles.dropdownBtn, styles.dropdownBtn]}
        size="small"
      >
        {drodownTitle}
      </Dropdown.Button>
    </Dropdown>
  )
}

export default TeammatesDropdown
