import { createSelector } from 'reselect'
import { selectAccountUsageOnboarding } from 'selectors/app'
import { selectCanOnboardAccount } from '.'
import { selectShowOnboardingWorkflow } from './selectShowOnboardingWorkflow'
import { selectShowOnboardingWorkflowInConversationList } from './selectShowOnboardingWorkflowInConversationList'

let isInitiallyCompleted = null
export const selectShowOnboardingWorkflowProgressIndicator = createSelector(
  selectCanOnboardAccount,
  selectShowOnboardingWorkflow,
  selectAccountUsageOnboarding,
  selectShowOnboardingWorkflowInConversationList,
  (
    canOnboardAccount,
    showOnboardingWorkflow,
    usageOnboarding,
    showOnboardingWorkflowInConversationList
  ) => {
    if (showOnboardingWorkflowInConversationList) return false
    if (isInitiallyCompleted === null)
      isInitiallyCompleted = !!usageOnboarding.completed
    return (
      showOnboardingWorkflow || (canOnboardAccount && !isInitiallyCompleted)
    )
  }
)
