import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { redirect } from 'redux-first-router'
import Modal from '@groovehq/internal-design-system/lib/components/Modal/Modal'
import ProgressDots from '@groovehq/internal-design-system/lib/components/ProgressDots/ProgressDots'
import { useDrawer } from 'ducks/drawers/hooks'
import { buildDrawerQueryParam, getCurrentDrawerId } from 'ducks/drawers/util'
import { selectQueryParams } from 'selectors/location'
import { SETTINGS_CHANNELS_PAGE } from 'subapps/settings/types'
import { useFeature } from 'ducks/billing/hooks'
import { FEATURE_INBOX_MAX_MAILBOXES } from 'ducks/billing/featureTypes'
import { selectChannelCreationStepCount } from 'ducks/channels/selectors'
import { useDrawerCommons } from 'ducks/drawers/hooks/useDrawerCommons'
import ChannelOptions from './ChannelOptions'
import { useAdminAccess } from '../../NoAccess/hooks'
import {
  CHANNEL_EMAIL_TYPES,
  CHANNEL_TYPES,
  SECONDARY_EMAIL_CHANNEL,
} from '../Channels.data'
import { styles } from './styles'
import ChannelOption from './ChannelOptions/ChannelOption'

const SelectEmailChannel = ({
  onClose,
  onExit,
  previousDrawer,
  drawerSource: source = 'settings',
  drawerIsSecondary: isSecondaryType = false,
  drawerId,
}) => {
  const dispatch = useDispatch()
  const [selectedChannel, setSelectedChannel] = useState(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { canUseFeature } = useFeature(FEATURE_INBOX_MAX_MAILBOXES)
  const showPlanLimitationForMailboxes = !canUseFeature
  const queryParams = useSelector(selectQueryParams)
  // get the current drawer id from the url string in browser address bar
  const currentDrawerId = getCurrentDrawerId(queryParams)
  const creationStepCount = useSelector(state =>
    selectChannelCreationStepCount(state, CHANNEL_TYPES.google)
  )
  const { drawerId: nextDrawerId, openDrawer: openNextDrawer } = useDrawer(
    CHANNEL_EMAIL_TYPES[selectedChannel]?.drawer
  )

  const { updateDrawerParameter } = useDrawerCommons({ drawerId })

  useAdminAccess(onClose)

  useEffect(
    () => {
      if (isDrawerOpen && selectedChannel && currentDrawerId !== nextDrawerId) {
        // prevent drawer from being triggered to open again if the drawer is closed
        // i.e. closed being, the previous state's drawer is missing/changed from address bar query
        setSelectedChannel(null)
        setIsDrawerOpen(false)
      } else if (selectedChannel && currentDrawerId !== nextDrawerId) {
        // now useDrawer is initialised with value from currentDrawer
        // invoke openDrawer
        openNextDrawer('new', {
          query: {
            ...buildDrawerQueryParam(
              nextDrawerId,
              'drawerChannelType',
              selectedChannel
            ),
          },
          additionalProps: {
            drawerSource: source,
          },
        })
        setIsDrawerOpen(true)
      }
    },
    [
      currentDrawerId,
      nextDrawerId,
      selectedChannel,
      openNextDrawer,
      isDrawerOpen,
      source,
    ]
  )

  const channelList = useMemo(
    () => {
      return Object.keys(CHANNEL_EMAIL_TYPES)
        .map(type => {
          const channel = {
            type,
            ...CHANNEL_EMAIL_TYPES[type],
          }
          return channel
        })
        .filter(
          channel =>
            isSecondaryType ? channel.isSecondaryType : !channel.isSecondaryType
        )
    },
    [isSecondaryType]
  )

  const handleOpenNextDrawer = useCallback(
    type => {
      setSelectedChannel(type)
    },
    [setSelectedChannel]
  )

  const handleShowSecondaryChannels = useCallback(
    () => {
      updateDrawerParameter('drawerIsSecondary', 1)
    },
    [updateDrawerParameter]
  )

  const handleBack = useCallback(
    () => {
      if (isSecondaryType && previousDrawer) {
        updateDrawerParameter('drawerIsSecondary', null)
        return
      }
      onClose({ ignoreStack: false })
    },
    [onClose, isSecondaryType, updateDrawerParameter, previousDrawer]
  )

  useEffect(
    () => {
      if (showPlanLimitationForMailboxes) {
        onExit()
        dispatch(
          redirect({
            type: SETTINGS_CHANNELS_PAGE,
          })
        )
      }
    },
    [onExit, showPlanLimitationForMailboxes, dispatch]
  )

  return (
    <Modal
      onClose={onExit}
      portal="#drawer-root"
      dataTestId="drawer-select-email-channel"
      className="grui pb-14 mt-18"
      open
      backdropTransparency="light"
    >
      <ProgressDots
        count={creationStepCount}
        now={1}
        className="grui mt-4 mb-13"
      />
      <Modal.Title>Let’s Connect your {app.t('mailbox')}!</Modal.Title>
      <Modal.Description css={styles.description}>
        Connect the {app.t('mailbox')} you use for chatting with your customers,{` `}
        and all of your emails will automatically flow into Groove.
      </Modal.Description>
      <ChannelOptions
        channelList={channelList}
        onSelect={handleOpenNextDrawer}
        className="grui mt-19 mb-13"
      >
        {!isSecondaryType && (
          <ChannelOption
            title={SECONDARY_EMAIL_CHANNEL.title}
            icon={SECONDARY_EMAIL_CHANNEL.icon}
            type="secondary"
            onSelect={handleShowSecondaryChannels}
          >
            {SECONDARY_EMAIL_CHANNEL.description}
          </ChannelOption>
        )}
      </ChannelOptions>
      {(previousDrawer || isSecondaryType) && (
        <div className="grui text-center">
          <Modal.Button type="link" onClick={handleBack}>
            Back
          </Modal.Button>
        </div>
      )}
    </Modal>
  )
}

export default SelectEmailChannel
